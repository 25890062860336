import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./__styles__/card-centred.module.scss"

import Copy from "./../atoms/copy"

export default function Card({ svg, title, copy, link }) {
  const card = (
    <article className={styles.container}>
      <div className={styles.svgContainer}>
        <div className={styles.svgBox}>{svg()}</div>
      </div>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.copy}>
        <Copy copy={copy} />
      </div>
      <span className={styles.fakeLink}>
        {link.title ? link.title : "Find out more"}
      </span>
    </article>
  )

  return (
    <>
      {link.target === "_blank" || !link.url.includes("nhsstaffsurveys") ? (
        <a
          className={styles.link}
          href={link.url}
          target={link.target === "_blank" ? "_blank" : ""}
          rel="noreferrer"
          aria-label={title}
        >
          {card}
        </a>
      ) : (
        <Link
          className={styles.link}
          to={new URL(link.url).pathname}
          aria-label={title}
        >
          {card}
        </Link>
      )}
    </>
  )
}

Card.propTypes = {
  /**
   * The card's svg (function)
   */
  svg: PropTypes.func.isRequired,
  /**
   * The title that shows in the Card
   */
  title: PropTypes.string.isRequired,
  /**
   * HTML copy that shows in the Card
   */
  copy: PropTypes.string.isRequired,
  /**
   * The link in the CTA
   */
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
}
