import React, { useEffect } from "react"
import { graphql } from "gatsby"
import * as styles from "./__styles__/results.module.scss"
import { connect } from "react-redux"
import { updatePageURI } from "./../../redux/actions"

import Layout from "../layouts/base"
import SEO from "../atoms/seo"
import Hero from "../molecules/hero"
import Cards from "../organisms/cards-centred"
import CTA from "../molecules/cta"

// Redux
const mapDispatchToProps = {
  updatePageURIAction: updatePageURI,
}

function Page({ updatePageURIAction, data }) {
  const page = data.wpPage
  const cta = page.cta.ctaGroup
  const cards = page.cardsCentred.cardsItems

  useEffect(() => {
    updatePageURIAction(page.uri)
  })

  return (
    <Layout>
      <SEO
        description={page.seo.metaDesc}
        robots={page.seo.metaRobotsNoindex}
        slug={page.uri}
        title={page.seo.title}
      />
      <Hero
        title={page.hero.title || page.title}
        lead={page.hero.lead}
        pageId={page.id}
      />
      {cards && (
        <div className={styles.cards}>
          <Cards cards={cards} columns={2} />
        </div>
      )}
      {cta && (
        <div className={styles.cta}>
          <CTA text={cta.text} link={cta.link} />
        </div>
      )}
    </Layout>
  )
}

export default connect(null, mapDispatchToProps)(Page)

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      seo {
        title
        metaDesc
        metaRobotsNoindex
      }
      hero {
        title
        lead
      }
      cardsCentred {
        cardsItems {
          title
          copy
          link {
            url
            title
            target
          }
        }
      }
      cta {
        ctaGroup {
          text
          link {
            title
            url
            target
          }
        }
      }
    }
  }
`
