import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/cta.module.scss"

import Button from "./../atoms/button"

export default function CTA({ text, link }) {
  return (
    <section className={styles.container}>
      <h2 className={styles.text}>{text}</h2>
      <Button link={link} />
    </section>
  )
}

CTA.propTypes = {
  /**
   * The text that shows in the CTA
   */
  text: PropTypes.string.isRequired,
  /**
   * The link in the CTA
   */
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
}
