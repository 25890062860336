import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/cards-centred.module.scss"

import Card from "./../molecules/card-centred"

// Images
import image01 from "./../../images/svgs/cards/graph.svg"
import image02 from "./../../images/svgs/cards/dashboard.svg"
import image03 from "./../../images/svgs/cards/bars.svg"

export default function Cards({ cards, columns }) {
  const images = [image01, image02, image03, image03]

  return (
    <div className={styles.container}>
      {cards.map((card, index) => (
        <div
          className={styles.card}
          style={{ width: `calc((100% / ${columns}) - var(--gap))` }}
          key={index}
        >
          <Card
            svg={images[index % images.length]}
            title={card.title}
            copy={card.copy}
            link={card.link}
          />
        </div>
      ))}
    </div>
  )
}

Cards.propTypes = {
  /**
   * A list of cards (array of objects)
   */
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  /**
   * Number of columns (downloads per row)
   */
  columns: PropTypes.oneOf([2, 3]),
}

Cards.defaultProps = {
  columns: 3,
}
